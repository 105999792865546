import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { Section, InnerMedium } from '../components/Sections'
import color from '../constants/colors'

const Heading = styled.h2`
  color: ${color.main};
`

const InnerStyled = styled(InnerMedium)`
  padding: 100px 60px;
`

const ThankYouPaymentPage = ({ location: { pathname } }) => (
  <Layout>
    <SEO
      title="お申し込み・お支払い完了　| ITエンジニア専用のオンライン英会話スクール・Moba Pro IT English（モバプロ）"
      description="お申し込み・お支払い完了ページ"
      pathName={pathname}
      noFollowNoIndex
    />
    <Section>
      <InnerStyled>
        <Heading>お申し込み頂きありがとうございます。</Heading>
        <p>12回コースへのお申込みとクレジットカードでの授業料のお支払いが完了しました。</p>
        <p>決済サービス（Stripe）経由でお支払いのレシートのメールがご入力いただいたメールアドレス宛に届きます。</p>
        <p>また、別途、お申込みとお支払いを確認させていた上で通常3営業日以内にお客様のメールアドレス宛に運営より今後の流れに関して改めてご連絡いたします。</p>
        <p>お申し込み・お支払い済みの内容に関して何かご不明点等がございましたらinfo@moba-pro.com宛にメールでお問い合わせ下さい。</p>
      </InnerStyled>
    </Section>
  </Layout>
)

ThankYouPaymentPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
}

ThankYouPaymentPage.defaultProps = {
  location: null,
}

export default ThankYouPaymentPage
